function App() {
  const main = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    margin: '0 10vw',
  };

  const hOne = {
    fontWeight: 400,
    fontSize: '7.5vw',
    margin: 0,
  };

  const hThree = {
    fontWeight: 400,
    fontSize: '3vw',
    margin: 0,
  };

  return (
    <main style={main}>
      <h1 style={hOne}>Adedayo Moshood</h1>
      <h3 style={hThree}>I’m a frontend developer from Lagos, Nigeria.</h3>
    </main>
  );
}

export default App;
